import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

type IconType = { icon: CSSIconName | ImageIconName; invertColor?: boolean; style?: object }

const cssIconNames = ['checkYellow']
type CSSIconName = 'checkYellow'

export type ImageIconName =
    | 'group'
    | 'calendar'
    | 'lock'
    | 'clock'
    | 'connect'
    | 'chevronRight'
    | 'quote'
    | 'support'
    | 'questionMark'
    | 'cross'
    | 'showMore'
    | 'showLess'

type ImageIconType = { icon: ImageIconName; invertColor?: boolean; style?: object }

const ImageIcon = ({ icon, invertColor, style }: ImageIconType) => {
    const icons = useStaticQuery(graphql`
        fragment smallIconSquare on File {
            childImageSharp {
                fixed(width: 16, height: 16) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        fragment iconSquare on File {
            childImageSharp {
                fixed(width: 24, height: 24) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        query {
            group: file(relativePath: { eq: "icons/group.png" }) {
                ...smallIconSquare
            }
            calendar: file(relativePath: { eq: "icons/calendar.png" }) {
                ...smallIconSquare
            }
            lock: file(relativePath: { eq: "icons/lock.png" }) {
                ...smallIconSquare
            }
            checkYellow: file(relativePath: { eq: "icons/checkYellow.png" }) {
                ...smallIconSquare
            }
            clock: file(relativePath: { eq: "icons/clock.png" }) {
                ...smallIconSquare
            }
            connect: file(relativePath: { eq: "icons/personAdd.png" }) {
                ...smallIconSquare
            }
            chevronRight: file(relativePath: { eq: "icons/chevronRight.png" }) {
                ...iconSquare
            }
            quote: file(relativePath: { eq: "icons/quote.png" }) {
                ...iconSquare
            }
            support: file(relativePath: { eq: "icons/support.png" }) {
                ...iconSquare
            }
            questionMark: file(relativePath: { eq: "icons/questionMark.png" }) {
                ...iconSquare
            }
            cross: file(relativePath: { eq: "icons/cross.png" }) {
                ...iconSquare
            }
            showMore: file(relativePath: { eq: "icons/showMore.png" }) {
                ...iconSquare
            }
            showLess: file(relativePath: { eq: "icons/showLess.png" }) {
                ...iconSquare
            }
        }
    `)
    return (
        <Img
            imgStyle={invertColor ? { filter: 'invert(1)' } : {}}
            style={style}
            fixed={icons[icon].childImageSharp.fixed}
        />
    )
}

const CSSIcon = styled.i<{ icon: CSSIconName }>`
    display: inline-block;

    ${props =>
        props.icon === 'checkYellow'
            ? `
        width: 18px;
        height: 18px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAd1JREFUOBGlkj0vw1EUxp/7r3oJAwufwUgYDRaDl0mIRMQiJDbfoL4AgxhsRmGhqCqNl5CImCxWJLZKB/FSyvGcU7dpq0Lqyr/3uvee333Ocw5Q4RBBIAeoqjA8FyarCP0LoMFeheygU6KYrggoSwgbLI522cK9nEFkG2O69+ccFeKm8CaEIIt9VKMJKWTIuFaQDRrn/LrcnFeyaUrSTEtkj99WLrUiz36CfYPECEgaZFkflQgCnQMBnOyi2Tke6pqfHujIp0Ml3E2yVo1WrxdcMnwqd+vrl/ImCSqSaUBvrE8nbnf03iO/VnuooA3U7BsTV4NFXgi5fizIBY3toLExtOGdSsJU8sa/Wq4ymHB9uNI2cN20vXAQsG15q7IoZuy1DXTIJtKSMCXPcmjzvJ0VKPEc84Mv91DVrvHVug+sQNBNBS1UkEEdavCME6rtMhCrrJ56iM6BVsv1IkHIMQMVkkU9RthhLXhlOlUGSTFsVAO01KUQ3Q+wZg5prea+3nB4JCxLpOQ6mfO4G8Ct+TJM18qMfKn1jO1+zo7tZDIv/DeEBoIeECFktqy5pUDfmTR9yEw9orGnZu66v/tTw/rzIkW6yaoNMtl2Lu+Y0jLVPGn3ughT/c/4TYlnfwLyJOe6/xLPIwAAAABJRU5ErkJggg==');
    `
            : null}
`

const Icon = ({ icon, invertColor, style }: IconType) => {
    if (cssIconNames.includes(icon)) {
        return <CSSIcon icon={icon as CSSIconName} />
    }

    return <ImageIcon icon={icon as ImageIconName} invertColor={invertColor} style={style} />
}

export default Icon
