import React from 'react'
import styled from 'styled-components'
import InputField from './InputField'
import { LargeButton } from './Button'
import { BREAKPOINTS, PADDING_STANDARD } from './tokens'
import appendQueryParameters from '../utils/getQueryParams'

const HeroForm = styled.form`
    margin: 0 auto;
    padding: 0 16px 0;
    width: 100%;
    max-width: 630px;
    display: flex;
    flex-direction: column;
`

const JoinButton = styled(LargeButton)`
    margin-top: ${PADDING_STANDARD / 2}px;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        margin-top: 0;
    }
`

const Headline = styled.div`
    font-family: Bryant, sans-serif;
    margin: 12px 0 0 0;
    font-size: 2em;
    font-weight: bold;
    letter-spacing: -1.11px;
    line-height: 30px;
`

const Container = styled.div`
    &.is-transitioned {
        position: fixed;
        top: 0;

        background-color: #ffffff;
        box-shadow: 0 1px 5px fade(#333333, 25%);
    }

    position: relative;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;

    background-color: transparent;
    box-shadow: none;
    backface-visibility: hidden;

    transition: background-color 0.3s linear;

    .mixin-respondTo(tablet, {height: 70px; top: 8px;});
`

const FormWidth = styled.div`
    position: relative;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1020px;
`

const Pos = styled.div`
    display: block;

    background: rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 1px;
    border-color: #e6e6e6;
    border-radius: 4px;
    top: -70px;
    z-index: 194;
    width: 309px;
    height: 300px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    > p {
        font-size: 14px;
        margin: 6px auto 12px;
    }

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        top: -200px;
        right: 10px;
        left: auto;
    }
`

const HeroOverlay = ({ currentUrl, params }: { currentUrl: string; params: string }) => (
    <Container>
        <FormWidth>
            <Pos>
                <Headline>Join InterNations</Headline>
                <p>The world's largest expat community</p>
                <HeroForm
                    action={appendQueryParameters(
                        '/registration/preset/?trigger=hero_join&registration_type=email',
                        currentUrl,
                        params,
                    )}
                    method="post"
                >
                    <InputField type="text" name="first_name" placeholder="First name" autoFocus />
                    <InputField type="text" name="last_name" placeholder="Last name" />
                    <InputField type="email" name="email" placeholder="Email" />
                    <JoinButton className="t-join-now-button" type="submit">
                        Join now
                    </JoinButton>
                </HeroForm>
            </Pos>
        </FormWidth>
    </Container>
)

export default HeroOverlay
