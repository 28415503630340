import React, { useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { PADDING_STANDARD, COLOR_WHITE, FONT_FAMILY_BRAND, BREAKPOINTS } from '../tokens'

export const Heading: StyledComponent<'button', {}, { isExpanded?: boolean }> = styled.button.attrs(
    (props: { isExpanded?: boolean }) => ({
        rotation: props.isExpanded ? '-135' : '45',
    }),
)`
    display: block;
    width: 100%;
    text-align: inherit;
    margin: 0 0 ${PADDING_STANDARD}px 0;
    padding: 0;
    color: ${COLOR_WHITE};
    font-size: ${PADDING_STANDARD}px;
    font-family: ${FONT_FAMILY_BRAND};
    font-weight: 600;
    line-height: 1;
    border: 0;
    background-color: transparent;

    text-transform: uppercase;
    position: relative;

    &:after {
        position: absolute;
        right: 10px;
        content: '';
        border: solid ${COLOR_WHITE};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(${props => props.rotation}deg);
        @media (min-width: ${BREAKPOINTS.tablet}px) {
            display: none;
        }
    }
`

export const List = styled.ul<{ inline?: boolean; isVisible?: boolean }>`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: ${props => (props.isVisible ? 'block' : 'none')};

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        display: block;
    }

    > li {
        margin-bottom: ${PADDING_STANDARD * 1.5}px;
        display: ${props => (props.inline ? 'inline-block' : 'inherit')};
        padding-right: ${props => (props.inline ? `${PADDING_STANDARD / 2}px` : '0px')};

        @media (min-width: ${BREAKPOINTS.tablet}px) {
            margin-bottom: inherit;
        }
    }
`

export type LinkListType = {
    [url: string]: string
}

type NavigationSectionType = {
    sectionName: string
    links: LinkListType
    inline?: boolean
    children: (props: { url: string; title: string }) => React.ReactNode
}

const NavigationSection = ({ sectionName, links, inline, children }: NavigationSectionType) => {
    const [showList, setShowList] = useState(false)
    const onHeadingClick = () => setShowList(!showList)
    const expandable = inline ? true : showList

    return (
        <nav key={sectionName} aria-labelledby={`footer-navigation-${sectionName}`}>
            <Heading onClick={onHeadingClick} id={`footer-navigation-${sectionName}`} isExpanded={expandable}>
                {sectionName.replace(/-/g, ' ')}
            </Heading>
            <List inline={inline} isVisible={expandable} aria-hidden={!expandable}>
                {Object.entries(links).map(([url, title]) => (
                    <li key={url}>{children({ url, title })}</li>
                ))}
            </List>
        </nav>
    )
}

export default NavigationSection
