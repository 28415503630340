import React from 'react'
import Modal, { ModalType } from './Modal'
import { GeographyType } from '../../types/wordpress'

type ModalOptions = Pick<ModalType, 'title' | 'subTitle' | 'trigger'>
type State = { isOpen: boolean } & ModalOptions
type Action = { type: 'modal open'; payload?: ModalOptions } | { type: 'modal close' }
type Dispatch = (action: Action) => void

const ModalDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function modalReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'modal open': {
            const options = action.payload
            return { ...state, ...options, isOpen: true }
        }

        case 'modal close':
            return { ...state, isOpen: false }

        default:
            return state
    }
}

export const actions = {
    openModal: (options?: ModalOptions) => ({ type: 'modal open' as 'modal open', payload: options }),
    closeModal: () => ({ type: 'modal close' as 'modal close' }),
}

export default function ModalProvider({
    children,
    geography,
    currentUrl,
    params,
}: {
    children: React.ReactNode
    geography: GeographyType
    currentUrl: string
    params: string
}) {
    const [state, dispatch] = React.useReducer(modalReducer, { isOpen: false, title: '', subTitle: '', trigger: '' })
    const closeModal = () => dispatch(actions.closeModal())

    return (
        <ModalDispatchContext.Provider value={dispatch}>
            {children}
            {state.isOpen ? (
                <Modal
                    trigger={state.trigger}
                    onClose={closeModal}
                    title={state.title}
                    subTitle={state.subTitle}
                    geography={geography}
                    currentUrl={currentUrl}
                    params={params}
                />
            ) : null}
        </ModalDispatchContext.Provider>
    )
}

export function useModalDispatch() {
    const context = React.useContext(ModalDispatchContext)
    if (context === undefined) {
        throw new Error('useModalDispatch must be used within a ModalProvider')
    }
    return context
}
