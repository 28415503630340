import React from 'react'
import styled from 'styled-components'
import { LargeButton } from '../Button'
import { BOX_SHADOW_Z3, BREAKPOINTS } from '../tokens'

const JoinNow = styled.a`
    display: block;
    width: auto;
    vertical-align: middle;
    margin: 0 auto;
`

const FooterButton = styled(LargeButton)`
    width: 100%;
    padding: 0;
    border-radius: 0;
    height: 50px;
    box-shadow: ${BOX_SHADOW_Z3};
`

const HideAboveMobile = styled.div`
    bottom: 0;
    position: sticky;
    @media (min-width: ${BREAKPOINTS.stickyFooterMobile}px) {
        display: none;
    }
    width: 100%;
`

const StickyFooterButton = () => (
    <HideAboveMobile>
        <JoinNow href="/registration?trigger=sticky_footer_join" title="InterNations | Join Now">
            <FooterButton>Join Now</FooterButton>
        </JoinNow>
    </HideAboveMobile>
)

export default StickyFooterButton
