import styled from 'styled-components'
import { COLOR_GREY_90, COLOR_WHITE, DEFAULT_BORDER_RADIUS, COLOR_BLACK_54 } from './tokens'

interface WhiteBoxProps {
    noPadding?: boolean
    noBorder?: boolean
}

const WhiteBox = styled.div<WhiteBoxProps>`
    padding: ${props => (props.noPadding ? '0px' : '24px 16px')};
    border: ${props => (props.noBorder ? 'none' : `1px solid ${COLOR_GREY_90}`)};
    border-radius: ${DEFAULT_BORDER_RADIUS}px;
    background: ${COLOR_WHITE};
    div {
        p {
            color: ${COLOR_BLACK_54};
        }
        ul {
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            color: ${COLOR_BLACK_54};
            margin-top: 0;
            margin-bottom: 24px;
            li {
                margin-left: -20px;
                list-style: none;
            }
            li:before {
                content: '·';
                font-size: 32px;
                vertical-align: middle;
                line-height: 20px;
                padding-right: 22px;
            }
        }
        ol {
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            color: ${COLOR_BLACK_54};
            margin-top: 0;
            margin-bottom: 24px;
        }

        p + ul {
            margin-top: -16px;
        }

        p + h2,
        ul + h2 {
            margin-top: 40px !important;
        }
    }
`
export default WhiteBox
