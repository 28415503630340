const appendQueryParameters = (url: string, currentUrl: string, params: string): string => {
    const newUrl = new URL(url, currentUrl)
    const urlParams = new URLSearchParams(params)

    for (const [key, value] of urlParams.entries()) {
        newUrl.searchParams.append(key, value)
    }

    return newUrl.toString()
}

export default appendQueryParameters
