import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { FluidImage } from '../../types/wordpress'

const StaticTestimonialImageWrapper = styled.div`
    margin: 0 30;
`
export const TestimonialImage = ({ image }: { image: FluidImage }) => (
    <StaticTestimonialImageWrapper>
        <Img fluid={image.localFile.childImageSharp.fluid} />
    </StaticTestimonialImageWrapper>
)

export const StaticTestimonialImage = ({ gender }: { gender: string }) => {
    const userImage = useStaticQuery(graphql`
        query {
            male: file(relativePath: { eq: "header/testimonial_male.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            female: file(relativePath: { eq: "header/testimonial_female.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <StaticTestimonialImageWrapper>
            <Img fluid={userImage[`${gender}`].childImageSharp.fluid} />
        </StaticTestimonialImageWrapper>
    )
}
