import React from 'react'
import styled from 'styled-components'
import { COLOR_WHITE, BREAKPOINTS } from '../tokens'
import HeroBenefits from '../HeroBenefits'
import StaticMetaInfo from './StaticMetaInfo'
import Testimonials from './Testimonials'
import StaticIntroduction from './StaticIntroduction'
import Introduction from './Introduction'
import { HeroStaticHeading } from '../typography'
import { GeographyType, THero } from '../../types/wordpress'

const StaticContentWrapper = styled.div`
    width: 320px;
    background-color: ${COLOR_WHITE};
    display: block;
    background: rgba(255, 255, 255, 0);
    border-style: none;
    border-radius: 0px;
    left: 10px;
    top: 170px;
    z-index: 252;
    margin: 0 auto;
    padding-top: 170px;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        top: 0;
        width: 100%;
        padding-top: 0;
    }
`

const StaticContentContainer = styled.div`
    align-items: center;
    width: 100%;
    max-width: 1020px;
    padding: 25px;
    background-color: ${COLOR_WHITE};
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        width: 100%;
        padding: 0 25px 25px;
    }
`

type StaticContentProps = { hero: THero; geography: GeographyType }

const StaticContent = ({ hero, geography }: StaticContentProps) => {
    return (
        <StaticContentWrapper>
            <StaticContentContainer>
                <HeroStaticHeading>{hero.headline}</HeroStaticHeading>
                <HeroBenefits uniqueValuePropositions={hero.uniqueValuePropositions} />
                {hero.enabled === 'static' ? (
                    <>
                        <StaticIntroduction geography={geography} />
                        <Testimonials
                            headline="What Members are saying"
                            testimonials={[
                                {
                                    quote:
                                        'Everyone should join InterNations to enjoy everything from business events to networking to cultural and travel experiences.',
                                    name: 'Ranim, InterNations Cairo',
                                    gender: 'female',
                                },
                                {
                                    quote:
                                        "InterNations helped me meet many people of different cultures - now I'm more open-minded and happier!",
                                    name: 'Nicholas, InterNations Yaounde',
                                    gender: 'male',
                                },
                            ]}
                        />
                    </>
                ) : null}
                {hero.enabled === 'enabled' ? (
                    <>
                        <Introduction introduction={hero.header} />
                        <Testimonials
                            headline={hero.header.testimonialHeadline}
                            testimonials={hero.header.testimonials}
                        />
                    </>
                ) : null}

                {hero.communityStats ? <StaticMetaInfo /> : null}
            </StaticContentContainer>
        </StaticContentWrapper>
    )
}

export default StaticContent
