import React from 'react'
import styled from 'styled-components'
import { COLOR_BLUE, BREAKPOINTS, PADDING_STANDARD } from '../tokens'
import { HeroStaticSecondHeading } from '../typography'
import { HideAboveTablet, HideBelowTablet } from '../Layout'

const MetaInfoContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-row: auto auto;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    width: 100%;
    margin-top: 16px;
    padding: 16px 0 0;
    flex-wrap: wrap;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        display: flex;
        font-size: 0;
        flex-wrap: wrap;
        margin: -10px 0 0 -10px;
    }
`

const MetaInfoItem = styled.div`
    flex-basis: auto;
    justify-content: center;

    /* IE11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex-basis: 50%;
        align-items: center;
    }

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        display: inline-block;

        margin: 10px 0 0 10px;
        flex-grow: 1;
        height: 100px;
        width: 100px;
    }
`

const FactNumbers = styled.p`
    font-family: Bryant, sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${COLOR_BLUE};
    margin: 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        font-size: 45px;
    }
`

const FactText = styled.p`
    font-family: Bryant, sans-serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${COLOR_BLUE};

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        font-size: 14px;
    }
`

const Fact = styled.div`
    text-align: center;
    margin: 0 ${PADDING_STANDARD / 2}px;
`

const StaticMetaInfo = () => {
    return (
        <>
            <HeroStaticSecondHeading style={{ marginTop: '10px' }}>
                InterNations Worldwide at a Glance
            </HeroStaticSecondHeading>
            <MetaInfoContainer>
                <MetaInfoItem>
                    <Fact>
                        <HideAboveTablet>
                            <FactNumbers>5M</FactNumbers>
                            <FactText>Members</FactText>
                        </HideAboveTablet>
                        <HideBelowTablet>
                            <FactNumbers>420</FactNumbers>
                            <FactText>Cities Worldwide</FactText>
                        </HideBelowTablet>
                    </Fact>
                </MetaInfoItem>
                <MetaInfoItem>
                    <Fact>
                        <HideAboveTablet>
                            <FactNumbers>200+</FactNumbers>
                            <FactText>Destination Guides</FactText>
                        </HideAboveTablet>
                        <HideBelowTablet>
                            <FactNumbers>5M</FactNumbers>
                            <FactText>Members around the world</FactText>
                        </HideBelowTablet>
                    </Fact>
                </MetaInfoItem>
                <MetaInfoItem>
                    <Fact>
                        <HideAboveTablet>
                            <FactNumbers>420</FactNumbers>
                            <FactText>Cities Worldwide</FactText>
                        </HideAboveTablet>
                        <HideBelowTablet>
                            <FactNumbers>6,000</FactNumbers>
                            <FactText>events and activities each month</FactText>
                        </HideBelowTablet>
                    </Fact>
                </MetaInfoItem>
                <MetaInfoItem>
                    <Fact>
                        <HideAboveTablet>
                            <FactNumbers>6,000</FactNumbers>
                            <FactText>events each month</FactText>
                        </HideAboveTablet>
                        <HideBelowTablet>
                            <FactNumbers>200+ </FactNumbers>
                            <FactText>Destination Guides</FactText>
                        </HideBelowTablet>
                    </Fact>
                </MetaInfoItem>
            </MetaInfoContainer>
        </>
    )
}

export default StaticMetaInfo
