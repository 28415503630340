import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS, PADDING_STANDARD } from './tokens'
import Icon from './Icon'
import Copy from './Copy'

const BenefitsContainer = styled.ul`
    display: block;
    margin: 0;
    padding: ${PADDING_STANDARD}px;
    li:last-child {
        display: none;
    }

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        display: flex;
        flex-wrap: wrap;
        max-width: 710px;
        li:last-child {
            display: flex;
        }
    }
`
const Benefit = styled.li`
    display: flex;
    margin-bottom: ${PADDING_STANDARD / 4}px;
    flex-basis: 50%;
    align-items: center;

    /* IE11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex-basis: auto;
        width: 50%;
    }
`
const BenefitText = styled.div`
    margin-left: ${PADDING_STANDARD / 4}px;

    p {
        margin: 0;
    }
`
const HeroBenefits = ({ uniqueValuePropositions }: { uniqueValuePropositions: { copy: string }[] }) => (
    <BenefitsContainer className="t-seo-value-proposition">
        {uniqueValuePropositions.map(({ copy }) => (
            <Benefit key={copy.substr(0, 5)}>
                <Icon icon="checkYellow" />
                <BenefitText>
                    <Copy markdown={copy} />
                </BenefitText>
            </Benefit>
        ))}
    </BenefitsContainer>
)

export default HeroBenefits
