import React from 'react'
import unified from 'unified'
import parse from 'remark-parse'
import withAttrs from 'remark-attr'
import toHtml from 'remark-rehype'
import toReact from 'rehype-react'
import sanitize from 'rehype-sanitize'
import merge from 'lodash/merge'

// Fixme: https://issues.internations.org/browse/INOTF-1838
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ghSchema from 'hast-util-sanitize/lib/github'

const schema = merge(ghSchema, {
    // Do not prefix potentially clobbering
    clobberPrefix: '',
    // Allow rel and target attrs for <a />
    attributes: { a: ['rel', 'target', 'href'] },
})

const processor = unified()
    .use(parse, { commonmark: true })
    .use(withAttrs)
    .use(toHtml)
    .use(sanitize, schema)
    .use(toReact, {
        createElement: React.createElement,
        Fragment: React.Fragment,
    })

const Copy = ({ markdown }: { markdown: string }) => {
    const { contents: Content } = processor.processSync(markdown)

    return <>{Content}</>
}

export default Copy
