export const corporateLinks = {
    about: {
        '/about-internations/': 'About InterNations',
        '/career/#team': 'Team',
        '/career/': 'Careers',
        '/press/press-release/': 'Press',
        '/changemakers/': 'Changemakers',
        '/advertise-with-us/': 'Advertise with us',
        'https://blog.internations.org': 'Blog',
    },
    explore: {
        '/all-internations-countries/': 'Communities by country',
        '/all-internations-cities/': 'Communities by city',
        '/guide/all/': 'Country & City Guides',
        '/world-forum/': 'Worldwide Forum',
    },
    essentials: {
        'https://support.internations.org/hc/en-us': 'FAQ',
        '/support/request/': 'Request support',
        '/magazine/': 'Magazine',
        '/expat-insider/': 'Expat Insider',
    },
    community: {
        '/get-involved/': 'Get involved',
        '/get-involved/internations-ambassador/': 'InterNations Ambassador',
        '/get-involved/internations-consul/': 'InterNations Consul',
        '/guidelines/': 'Community Guidelines',
    },
}

export const socialLinks = {
    'Follow-Us': {
        'https://www.facebook.com/InterNations.org': 'facebook',
        'https://www.linkedin.com/company/internations': 'linkedin',
        'https://www.instagram.com/internationsorg/': 'instagram',
        'https://www.youtube.com/user/InterNationsVideos': 'youtube',
    },

    'Download-Our-Apps': {
        'https://itunes.apple.com/app/internations/id1059342646?mt=8': 'applestore',
        'https://play.google.com/store/apps/details?id=org.internations': 'googleplay',
    },
}
