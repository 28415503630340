const shadeColor = (color: string, percent: number) => {
    const f = color.split(',')
    const t = percent < 0 ? 0 : 255
    const p = percent < 0 ? percent * -1 : percent
    const R = parseInt(f[0].slice(4), 10)
    const G = parseInt(f[1], 10)
    const B = parseInt(f[2], 10)
    return `rgb(${Math.round((t - R) * p) + R},${Math.round((t - G) * p) + G},${Math.round((t - B) * p) + B})`
}

export const COLOR_TRANSPARENT = 'rgba(0, 0, 0, 0)'
export const COLOR_TRANSPARENT_HIGH_OPACITY = 'rgba(0, 0, 0, 0.6)'
export const COLOR_NEUTRAL = 'rgb(200, 200, 200)'
export const COLOR_NEUTRAL_BRIGHT = shadeColor(COLOR_NEUTRAL, 0.5)

export const COLOR_ORANGE = '#ff9e1b'
export const COLOR_DARK_BLUE = '#0e3247'
export const COLOR_BLUE = '#1e70a1'
export const COLOR_HOVER_BLUE = '#1a5e86'
export const COLOR_LIGHT_BLUE = '#e6edf2'
export const COLOR_HOVER_LIGHT_BLUE = '#e1e8ed'

export const COLOR_GREY_20 = '#333333'
export const COLOR_GREY_40 = '#666666'
export const COLOR_GREY_60 = '#999999'
export const COLOR_GREY_90 = '#e6e6e6'
export const COLOR_GREY_96 = '#f5f6f7'
export const COLOR_WHITE = '#ffffff'
export const COLOR_BLACK_54 = 'rgba(0, 0, 0, 0.54)'
export const COLOR_BLACK_87 = 'rgba(0, 0, 0, 0.87)'

export const BOX_SHADOW_Z1 =
    '0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 3px 0 rgba(0, 0, 0, .2)'
export const BOX_SHADOW_Z3 =
    '0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12), 0 1px 8px 0 rgba(0, 0, 0, .2)'

export const DEFAULT_BORDER_RADIUS = 4
export const COLOR_BLACK_TRANSPARENT = 'rgba(0, 0, 0, 0.3)'
export const FONT_FAMILY_BRAND = 'Bryant, sans-serif'
export const PADDING_STANDARD = 24

export const BREAKPOINTS = {
    mobile: 0,
    specialMobile: 400,
    stickyFooterMobile: 450,
    staticHeaderTablet: 600,
    tablet: 768,
    desktop: 1024,
    wideDesktop: 1300,
}

export const MAX_PAGE_WIDTH = 1020
