import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { GatsbyLocalFluidImage } from '../HeroImage'

import { BREAKPOINTS } from '../tokens'

const Hero = () => {
    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "header/hero.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return <Img loading="eager" style={{ maxHeight: '300px' }} fluid={data.hero.childImageSharp.fluid} />
}

type HeroImageProps = {
    image: GatsbyLocalFluidImage | undefined
}

const HeroWrapper = styled.div`
    display: block;
    background: rgba(255, 255, 255, 0);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 320px;
    height: 176px;
    position: relative;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        position: relative;
        max-width: 1280px;
    }
`

const StaticHeroImage = ({ image }: HeroImageProps) => {
    return (
        <HeroWrapper>
            {image ? (
                <Img loading="eager" style={{ maxHeight: '300px' }} fluid={image.localFile.childImageSharp.fluid} />
            ) : (
                <Hero />
            )}
        </HeroWrapper>
    )
}

export default StaticHeroImage
