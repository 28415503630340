import React, { useEffect, MouseEvent } from 'react'
import styled from 'styled-components'
import { LargeButton } from '../Button'
import EmailInput from '../EmailInput'
import Icon from '../Icon'
import {
    COLOR_WHITE,
    DEFAULT_BORDER_RADIUS,
    PADDING_STANDARD,
    COLOR_DARK_BLUE,
    COLOR_GREY_90,
    BREAKPOINTS,
} from '../tokens'
import { GeographyType } from '../../types/wordpress'
import appendQueryParameters from '../../utils/getQueryParams'

export type ModalType = {
    onClose: () => void
    title: string
    subTitle: string
    trigger: string
    geography: GeographyType
    currentUrl: string
    params: string
}

export const Heading1 = styled.h1`
    margin-top: 24px;
    margin-bottom: 8px;

    font-family: Bryant, sans-serif;
    font-weight: normal;
    font-size: 35px;
    line-height: 42px;
    letter-spacing: -1.2px;
    color: ${COLOR_DARK_BLUE};

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        font-size: 45px;
        line-height: 54px;
        letter-spacing: -1.38px;
    }
`

export const SubHeading1 = styled.p`
    display: none;
    margin: 0;
    font-size: 18px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        display: block;
    }
`
const ModalBackdrop = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.6);
    padding: ${PADDING_STANDARD}px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        justify-content: center;
    }
`
const Modal = styled.div`
    position: relative;
    background-color: ${COLOR_WHITE};
    box-shadow: 0 0 17px rgba(1, 1, 1, 0.7);
    border-radius: ${DEFAULT_BORDER_RADIUS}px;
    text-align: center;
    padding: 24px ${PADDING_STANDARD}px;
    width: 100%;
    max-width: 650px;
`
const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    padding: 12px;
    outline: none;
    cursor: pointer;
`
const InputContainer = styled.form`
    display: flex;
    margin-top: 22px;
    margin-bottom: ${PADDING_STANDARD / 2}px;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: row;
    }
`

const JoinButton = styled(LargeButton)`
    margin-top: ${PADDING_STANDARD / 2}px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin-top: 0;
    }
`

const ModalFooter = styled.div`
    margin-top: ${PADDING_STANDARD}px;
`
const Checklist = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    border: 1px solid ${COLOR_GREY_90};
    padding: ${PADDING_STANDARD / 2}px;
`
const ListItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`
const ItemText = styled.span`
    padding-left: 8px;
`
const Order = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: column-reverse;
    }
`

const getCountryShortNounPhrase = (geography: GeographyType): string => {
    if (geography.type === 'country') {
        return geography.country.countryShortNounPhrase
    }
    return ''
}

const RegistrationModal = ({ onClose, title, subTitle, trigger, geography, currentUrl, params }: ModalType) => {
    const onKeyUp = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            onClose()
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keyup', onKeyUp)
        }
    })

    const stopClickPropagation = (event: MouseEvent) => event.stopPropagation()
    const countryShortNounPhrase = getCountryShortNounPhrase(geography)

    return (
        <ModalBackdrop onClick={onClose}>
            <Modal className="t-registration-modal" onClick={stopClickPropagation}>
                <CloseButton className="t-modal-close" onClick={onClose}>
                    <Icon icon="cross" />
                </CloseButton>
                <Heading1>{title}</Heading1>
                <SubHeading1>{subTitle}</SubHeading1>
                <Order>
                    <Checklist>
                        <div>
                            <ListItem>
                                <Icon icon="checkYellow" />
                                <ItemText>
                                    Connect with other <strong>expats in {countryShortNounPhrase}</strong>
                                </ItemText>
                            </ListItem>

                            <ListItem>
                                <Icon icon="checkYellow" />
                                <ItemText>
                                    Join <strong>exciting events</strong> to meet international people
                                </ItemText>
                            </ListItem>

                            <ListItem>
                                <Icon icon="checkYellow" />
                                <ItemText>
                                    Get <strong>tips &amp; information</strong> about {countryShortNounPhrase}
                                </ItemText>
                            </ListItem>
                        </div>
                    </Checklist>
                    <InputContainer
                        className="t-registration-form"
                        action={appendQueryParameters(
                            `/registration/preset/?trigger=${trigger}&registration_type=email`,
                            currentUrl,
                            params,
                        )}
                        method="post"
                    >
                        <EmailInput type="email" name="email" placeholder="Enter your email address..." />
                        <JoinButton type="submit">Join now</JoinButton>
                    </InputContainer>
                </Order>
                <ModalFooter>
                    Already a member? <a href="/login/">Log in here</a>.
                </ModalFooter>
            </Modal>
        </ModalBackdrop>
    )
}
RegistrationModal.defaultProps = {
    title: 'Network & connect',
    subTitle: 'Become a member to browse and search profiles.',
}

export default RegistrationModal
