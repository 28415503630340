import styled from 'styled-components'
import {
    DEFAULT_BORDER_RADIUS,
    PADDING_STANDARD,
    COLOR_GREY_60,
    COLOR_GREY_90,
    COLOR_GREY_20,
    COLOR_BLUE,
} from './tokens'

const InputField = styled.input`
    border-radius: ${DEFAULT_BORDER_RADIUS}px;
    border: 1px solid ${COLOR_GREY_60};
    box-shadow: inset 0 2px 0 ${COLOR_GREY_90};
    color: ${COLOR_GREY_20};
    flex: 1;
    font-family: inherit;
    font-size: 14px;
    height: 48px;
    line-height: normal;
    outline: none;
    padding: ${PADDING_STANDARD / 3}px;
    margin: 0 0 10px 0;

    &:focus {
        border-color: ${COLOR_BLUE};
        box-shadow: 0 0 0 1px ${COLOR_BLUE};
    }
`

export default InputField
