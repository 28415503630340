/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import styled from 'styled-components'
import ModalProvider from './modal/ModalContext'
import StaticHeroImage from '../components/StaticContent/StaticHeroImage'
import Header from './Header'
import Footer from './Footer/Footer'
import StickyFooterButton from './Footer/StickyFooterButton'
import { GatsbyLocalFluidImage } from '../components/HeroImage'
import StaticContent from '../components/StaticContent/StaticContent'
import HeroOverlay from '../components/HeroOverlay'
import { BREAKPOINTS, COLOR_WHITE } from './tokens'
import { GeographyType, THero } from '../types/wordpress'

type Props = {
    hero: THero
    children: React.ReactNode
    image?: GatsbyLocalFluidImage
    geography: GeographyType
    currentUrl: string
    params: string
}

const Container = styled.main`
    max-width: 1020px;
    margin: 0 auto;
    display: block; /* IE11 */
    word-break: break-word;
    position: relative;
`

const StaticContainer = styled.div`
    background-color: ${COLOR_WHITE};
    width: 100%;
`

const Layout = ({ hero, children, geography, currentUrl, params }: Props) => {
    return (
        <ModalProvider geography={geography} currentUrl={currentUrl} params={params}>
            <StaticContainer>
                <Header />
                <StaticHeroImage image={hero.image} />
                <HeroOverlay currentUrl={currentUrl} params={params} />
                <StaticContent hero={hero} geography={geography} />
            </StaticContainer>
            <Container>{children}</Container>
            <Footer />
            <StickyFooterButton />
        </ModalProvider>
    )
}

export const MainContent = styled.div`
    padding: 0 8px;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        flex-basis: 70%;
    }
`

export const SidebarContent = styled.div`
    padding: 0 8px;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        flex-basis: 30%;
    }
`

export const HideAboveTablet = styled.div`
    display: block;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        display: none;
    }
`

export const HideBelowTablet = styled.div`
    display: none;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

export const HideAboveMobile = styled.div`
    display: block;
    @media (min-width: ${BREAKPOINTS.specialMobile}px) {
        display: none;
    }
`

export const HideBelowMobile = styled.div`
    display: none;
    @media (min-width: ${BREAKPOINTS.specialMobile}px) {
        display: block;
    }
`

export default Layout
