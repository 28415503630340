import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Skyline from '../Skyline'
import { PADDING_STANDARD, COLOR_ORANGE, COLOR_DARK_BLUE, COLOR_BLUE, COLOR_WHITE } from '../tokens'
import Navigation from './Navigation'

const Footer = styled.footer`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: ${PADDING_STANDARD * 2}px;
`

const LegalContent = styled.a`
    font-size: 11px;
    margin: 0 ${PADDING_STANDARD / 2}px;
    color: inherit;
    display: inline-block;
`

const Content = styled.div`
    background: ${COLOR_BLUE};
    color: ${COLOR_WHITE};
    padding: ${PADDING_STANDARD}px;
`

const ContentExtra = styled.div`
    margin: 0;
    text-align: center;
    padding: ${PADDING_STANDARD / 2}px 0;
    background-color: ${COLOR_ORANGE};

    > a {
        text-decoration: underline;
        color: ${COLOR_DARK_BLUE};
    }
`

const FooterContainer = () => {
    const query = useStaticQuery(graphql`
        query {
            allWordpressWpLocalcommunities {
                totalCount
            }
        }
    `)
    const totalCount = query.allWordpressWpLocalcommunities.totalCount
    return (
        <Footer>
            <Skyline />
            <Navigation />
            <Content>
                <LegalContent as="p">
                    Made with ❤ in <span title="Germany">Munich</span> &amp; <span title="Lithuania">Vilnius</span>
                </LegalContent>
                <LegalContent href="/terms-and-conditions/" title="Terms &amp; Conditions">
                    Terms &amp; Conditions
                </LegalContent>
                <LegalContent href="/privacy-policy/" title="Privacy Policy">
                    Privacy Policy
                </LegalContent>
            </Content>
            <ContentExtra>
                <div>InterNations is present in {totalCount} cities worldwide. </div>
                <a href="/all-internations-cities/">See all InterNations Communities</a>
            </ContentExtra>
        </Footer>
    )
}

export default FooterContainer
