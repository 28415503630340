import React from 'react'
import styled from 'styled-components'
import NavigationSection, { LinkListType } from './NavigationSection'
import { corporateLinks, socialLinks } from './navigation-data'
import { PADDING_STANDARD, COLOR_BLUE, MAX_PAGE_WIDTH, BREAKPOINTS } from '../tokens'

const Icon = styled.i<{ icon: string }>`
    background-repeat: no-repeat;
    background-size: 199px;
    display: block;

${props =>
    props.icon === 'applestore'
        ? `
    width: 146px;
    height: 43px;
    background-position: -2px -2px;
    `
        : ''}

${props =>
    props.icon === 'googleplay'
        ? `
    width: 146px;
    height: 43px;
    background-position: -2px -50px;
    `
        : ''}

${props =>
    props.icon === 'facebook'
        ? `
    width: 43px;
    height: 43px;
    background-position: -2px -99px;
    `
        : ''}

${props =>
    props.icon === 'instagram'
        ? `
    width: 43px;
    height: 43px;
    background-position: -50px -99px;
    `
        : ''}

${props =>
    props.icon === 'linkedin'
        ? `
    width: 43px;
    height: 43px;
    background-position: -99px -99px;
    `
        : ''}

${props =>
    props.icon === 'youtube'
        ? `
    width: 43px;
    height: 43px;
    background-position: -153px -2px;
    `
        : ''}
`

const Wrapper = styled.div`
    background-color: ${COLOR_BLUE};
    padding: ${PADDING_STANDARD * 2}px ${PADDING_STANDARD}px 0;
`

const SocialSection = styled.div`
    button:after {
        display: none;
    }
`

const NavigationContainer = styled.div`
    text-align: left;
    display: grid;
    grid-template-columns: auto;
    margin: 0 auto;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        grid-template-columns: repeat(4, 1fr);
        max-width: 680px;

        // Center headings of 5 child
        ${SocialSection} {
            grid-area: 2 / 1 / 3 / 5;
            text-align: center;

            nav {
                margin-top: ${PADDING_STANDARD}px;
            }
        }
    }

    @media (min-width: ${BREAKPOINTS.desktop}px) {
        grid-template-columns: repeat(4, 1fr) auto;
        max-width: ${MAX_PAGE_WIDTH}px;

        ${SocialSection} {
            grid-area: auto;
            text-align: left;
            nav {
                margin-top: 0;
            }
        }
    }

    nav {
        margin-bottom: ${PADDING_STANDARD}px;
    }

    /* IE11 - fallback for grid */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        nav {
            float: left;
            margin-right: ${2 * PADDING_STANDARD}px;
        }

        ${SocialSection} {
            clear: left;
            padding-top: ${2 * PADDING_STANDARD}px;
        }
    }
`

const Link = styled.a`
    color: #fff;
    font-size: 12px;
`

const NavigationList = ({
    data,
    inline,
    children,
}: {
    data: {
        [sectionName: string]: LinkListType
    }
    inline?: boolean
    children: (props: { url: string; title: string }) => React.ReactNode
}) => {
    return (
        <>
            {Object.entries(data).map(([sectionName, links]) => (
                <NavigationSection key={sectionName} inline={inline} links={links} sectionName={sectionName}>
                    {children}
                </NavigationSection>
            ))}
        </>
    )
}

const Navigation = () => (
    <Wrapper>
        <NavigationContainer>
            <NavigationList data={corporateLinks}>
                {({ url, title }) => {
                    const isExternal = url.includes('https://')
                    return (
                        <>
                            {isExternal ? (
                                <Link
                                    key={url}
                                    href={url}
                                    title={`Go to ${title}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {title}
                                </Link>
                            ) : (
                                <Link key={url} href={url} title={`Go to ${title}`}>
                                    {title}
                                </Link>
                            )}
                        </>
                    )
                }}
            </NavigationList>
            <SocialSection>
                <NavigationList data={socialLinks} inline>
                    {({ url, title: icon }) => (
                        <Link key={url} href={url} title={`Go to ${icon}`} target="_blank" rel="noopener">
                            <Icon
                                icon={icon}
                                style={{ backgroundImage: 'url(/seo-assets/images/footer-sprite@2x.png)' }}
                            />
                        </Link>
                    )}
                </NavigationList>
            </SocialSection>
        </NavigationContainer>
    </Wrapper>
)

export default Navigation
