import React from 'react'
import styled from 'styled-components'
import { GeographyType } from '../../types/wordpress'
import { BREAKPOINTS } from '../tokens'
import { HeroStaticSecondHeading } from '../typography'

const Intro = styled.div`
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const TextContainer = styled.div`
    text-align: center;
    padding: 16px 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        text-align: left;
    }
`

const VideoContainer = styled.div`
    text-align: center;
    padding: 16px 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        text-align: left;
        padding: 16px 0 16px 16px;
    }
`

const VideoIFrame = ({ url }: { url: string }) => (
    <iframe title={url} src={`${url}?autoplay=1&muted=1`} width="100%" height="100%" frameBorder="0" allowFullScreen />
)

type StaticIntroductionProps = {
    geography: GeographyType
}

const getGeographyShortNounPhrase = (geography: GeographyType): string => {
    if (geography.type === 'country') {
        return geography.country.countryShortNounPhrase
    }

    if (geography.type === 'localcommunity') {
        return geography.localCommunity.country.countryShortNounPhrase
    }

    if (geography.type === 'upcomingCommunity') {
        return geography.upcomingCommunity.country.countryShortNounPhrase
    }

    return ''
}

const StaticIntroduction = ({ geography }: StaticIntroductionProps) => {
    return (
        <>
            <HeroStaticSecondHeading style={{ marginTop: '10px' }}>
                Discover Events &amp; Make New Friends
            </HeroStaticSecondHeading>
            <Intro>
                <TextContainer>
                    <p>
                        If you’re wondering how to navigate the challenges of life abroad, you’re not alone. Join
                        InterNations and connect with a community of like-minded expats in{' '}
                        {getGeographyShortNounPhrase(geography)}! You’ll find many ways to network, socialize, and make
                        new international friends.
                    </p>
                    <p>
                        Don’t miss a variety of welcoming online and in-person events. Join groups to pursue your
                        hobbies — from sports to music, there’s something for everyone With InterNations, you’ll quickly
                        feel at home wherever life takes you.
                    </p>
                </TextContainer>
                <VideoContainer>
                    <VideoIFrame url="https://player.vimeo.com/video/224345648?color=1E70A1&title=0&byline=0&portrait=0&wmode=opaque" />
                </VideoContainer>
            </Intro>
        </>
    )
}

export default StaticIntroduction
