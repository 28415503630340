import styled from 'styled-components'
import {
    COLOR_BLUE,
    COLOR_HOVER_BLUE,
    BOX_SHADOW_Z1,
    BOX_SHADOW_Z3,
    BREAKPOINTS,
    COLOR_GREY_90,
    COLOR_GREY_40,
} from './tokens'

const Button = styled.button.attrs(props => ({
    type: props.type,
}))`
    background: ${COLOR_BLUE};
    font-family: Bryant, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    white-space: nowrap;
    height: 40px;
    padding: 0 12px;
    border: none;
    box-shadow: ${BOX_SHADOW_Z1};
    border-radius: 4px;

    &:focus {
        outline: 0;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:hover {
        text-decoration: none;
        background-color: ${COLOR_HOVER_BLUE};
        box-shadow: ${BOX_SHADOW_Z3};
        cursor: pointer;
    }
`

type ButtonProps = {
    children: React.ReactNode
    type: 'button' | 'submit'
}

Button.defaultProps = {
    type: 'button',
}

export default Button

export const HeroButton = styled(Button)`
    float: right;
    background-color: ${COLOR_HOVER_BLUE};
    border-color: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.8);
    box-shadow: ${BOX_SHADOW_Z1};

    &:hover {
        text-decoration: none;
        background-color: ${COLOR_HOVER_BLUE};
        cursor: pointer;
        float: right;
        box-shadow: ${BOX_SHADOW_Z3};
    }
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        background-color: ${COLOR_BLUE};

        &:hover {
            text-decoration: none;
            background-color: ${COLOR_HOVER_BLUE};
        }
    }
`

export const HeroButtonGray = styled(Button)`
    display: none;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        background-color: ${COLOR_GREY_90};
        float: right;

        border-color: rgba(255, 255, 255, 0.3);
        color: ${COLOR_GREY_40};
        box-shadow: ${BOX_SHADOW_Z1};
        margin-right: 8px;
        display: initial;

        &:hover {
            text-decoration: none;
            background-color: ${COLOR_GREY_90};
        }
    }
`

export const LargeButton = styled(Button)`
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        height: 48px;
        padding: 0 20px;
        font-size: 18px;
    }
`

export const MobileButton = styled(Button)`
    width: 100%;
`
