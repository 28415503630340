import React from 'react'
import styled from 'styled-components'
import { StaticTestimonialImage, TestimonialImage } from './TestimonialImage'
import { HeroStaticSecondHeading } from '../typography'
import { BREAKPOINTS } from '../tokens'
import { THeroTestimonial } from '../../types/wordpress'
import Copy from '../Copy'

const TestimonialContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 16px 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        grid-template-columns: repeat(2, 1fr);

        > :first-child {
            margin-right: 40px;
        }

        > :last-child {
            margin-left: 40px;
        }
    }
`
const TestimonialItem = styled.div`
    margin: 0 auto;
    align-items: center;
    text-align: center;
`

const TestimonialText = styled.div`
    margin-top: 16px:
    display: block;
    background: rgba(255, 255, 255, 1);
    border-style: none;
    border-radius: 0px;
    left: 508px;
    top: 383px;
    text-align: center;
    line-height: 22px;
`

type TestimonialsProps = {
    testimonials: THeroTestimonial[]
    headline: string
}

type TestimonialProps = {
    testimonial: THeroTestimonial
}

const Testimonial = ({ testimonial }: TestimonialProps) => {
    return (
        <TestimonialItem>
            {testimonial.image ? (
                <TestimonialImage image={testimonial.image} />
            ) : (
                <StaticTestimonialImage gender={testimonial.gender} />
            )}

            <TestimonialText>
                <Copy markdown={testimonial.quote} />
                <p>
                    <em>
                        <strong>{testimonial.name}</strong>
                    </em>
                </p>
            </TestimonialText>
        </TestimonialItem>
    )
}

const Testimonials = ({ headline, testimonials }: TestimonialsProps) => {
    return (
        <>
            <HeroStaticSecondHeading style={{ marginTop: '10px' }}>{headline}</HeroStaticSecondHeading>
            <TestimonialContainer>
                <Testimonial testimonial={testimonials[0]} />
                <Testimonial testimonial={testimonials[1]} />
            </TestimonialContainer>
        </>
    )
}

export default Testimonials
