import React from 'react'
import styled from 'styled-components'
import { THeroHeader } from '../../types/wordpress'
import { BREAKPOINTS } from '../tokens'
import { HeroStaticSecondHeading } from '../typography'
import Copy from '../Copy'

const Intro = styled.div`
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const TextContainer = styled.div`
    text-align: center;
    padding: 16px 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        text-align: left;
    }
`

const VideoContainer = styled.div`
    text-align: center;
    padding: 16px 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        text-align: left;
        padding: 16px 0 16px 16px;
    }
`

const VideoIFrame = ({ url }: { url: string }) => (
    <iframe title={url} src={`${url}?autoplay=1&muted=1`} width="100%" height="100%" frameBorder="0" allowFullScreen />
)

type StaticIntroductionProps = {
    introduction: THeroHeader
}

const Introduction = ({ introduction }: StaticIntroductionProps) => {
    return (
        <>
            <HeroStaticSecondHeading style={{ marginTop: '10px' }}>{introduction.subheadline}</HeroStaticSecondHeading>
            <Intro>
                <TextContainer>
                    <Copy markdown={introduction.introduction} />
                </TextContainer>
                <VideoContainer>
                    <VideoIFrame url={introduction.videoUrl} />
                </VideoContainer>
            </Intro>
        </>
    )
}

export default Introduction
