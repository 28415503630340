import React from 'react'
import styled from 'styled-components'
import HeaderLogo from './HeaderLogo'
import { HeroButton, HeroButtonGray } from './Button'
import { HideAboveMobile, HideBelowMobile, HideAboveTablet, HideBelowTablet } from './Layout'
import { BREAKPOINTS } from './tokens'

const SeoPageHeader = styled.div`
    &.is-transitioned {
        position: fixed;
        top: 0;

        background-color: #ffffff;
        box-shadow: 0 1px 5px fade(#333333, 25%);
    }

    position: relative;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;

    background-color: transparent;
    box-shadow: none;
    backface-visibility: hidden;

    transition: background-color 0.3s linear;

    .mixin-respondTo(tablet, {height: 70px; top: 8px;});
`

const SeoPageHeaderWidth = styled.div`
    position: relative;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1020px;
`

const SeoPageHeaderLogo = styled.div`
    width: 42%;
    max-width: 216px;
    height: 48px;

    background-size: 100% auto;
`

const SeoPageHeaderButtons = styled.div`
    position: absolute;
    top: 50%;
    right: 8px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
`

const LogoContainer = styled.a`
    padding-left: 8px;

    background: rgba(255, 255, 255, 0);
    top: 26px;
    left: 10px;

    z-index: 202;
    position: absolute;

    @media (min-width: ${BREAKPOINTS.specialMobile}px) {
        top: 15px;
        left: 0px;
    }
`

const JoinNow = styled.a`
    padding: 6px 0 6px;
    display: inline-block;
    width: auto;
    vertical-align: middle;
`

const Login = styled.a`
    padding: 0 6px;
    margin-right: 8px;
    font-size: 13px;
    line-height: 56px;
    text-decoration: underline;
`

const Header = () => (
    <SeoPageHeader>
        <SeoPageHeaderWidth>
            <SeoPageHeaderLogo>
                <LogoContainer href="/" title="InterNations | Home">
                    <HideAboveMobile>
                        <HeaderLogo width={130} height={24} />
                    </HideAboveMobile>
                    <HideBelowMobile>
                        <HeaderLogo />
                    </HideBelowMobile>
                </LogoContainer>
            </SeoPageHeaderLogo>
            <SeoPageHeaderButtons>
                <HideAboveTablet>
                    <Login href="/login" title="InterNations | Log In">
                        Log In
                    </Login>
                </HideAboveTablet>
                <HideBelowTablet>
                    <JoinNow href="/login" title="InterNations | Log In">
                        <HeroButtonGray>Log In</HeroButtonGray>
                    </JoinNow>
                </HideBelowTablet>
                <JoinNow href="/registration?trigger=header_join" title="InterNations | Join Now">
                    <HeroButton>Join Now</HeroButton>
                </JoinNow>
            </SeoPageHeaderButtons>
        </SeoPageHeaderWidth>
    </SeoPageHeader>
)

export default Header
